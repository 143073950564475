@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Inter", sans-serif;
}
.container {
  // max-width: 1024px;
  // margin: 9px auto;
  // Медиа запросы
  @media screen and (max-width: 1200px) {
    // Стили для экранов шириной до 1200px
  }

  @media screen and (max-width: 992px) {
    // Стили для экранов шириной до 992px
  }

  @media screen and (max-width: 768px) {
    // Стили для экранов шириной до 768px
  }

  @media screen and (max-width: 991px) {
    padding: 0 10px;
  }
}
.notfound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  grid-column: 1 / -1;
  align-items: center;
  img {
    width: 200px;
  }
  p {
    font-size: 21px;
    font-weight: 700;
    margin-top: 50px;
    text-align: center;
  }
}
header {
  background: url(../image/header.jpg) center center no-repeat;
  background-size: cover;
  height: 741px;
  position: relative;
  display: table;
  width: 100%;
  &.diski {
    background: url(../image/diski.png) center center no-repeat;
    background-size: cover;
  }
  &.accessories {
    height: 70px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(10, 19, 9, 0.37);
    top: 0;
    left: 0;
  }
  .container {
    position: relative;
    z-index: 2;
  }

  h1 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 50px;
    @media screen and (max-width: 991px) {
      font-size: 29px;
    }
  }

  .top_line {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5px;
    padding-bottom: 5px;
    background: rgb(0,0,0,0.5);
    @media screen and (max-width: 1200px) {
      // Стили для экранов шириной до 1200px
    }

    @media screen and (max-width: 992px) {
      // Стили для экранов шириной до 992px
    }

    @media screen and (max-width: 768px) {
      // Стили для экранов шириной до 768px
    }

    @media screen and (max-width: 991px) {
      position: inherit;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      justify-items: center;
      background: none;
      padding: 0px;
      a {
        & + a {
          margin-left: 20px;
        }
      }
      .logo {
        grid-column: 1 / -1;
        order: 1;
      }
      .nav {
        grid-column: 1 / -1;
        order: 4;
      }
      .social_block {
        order: 3;
        
      }
      .phone_area {
        order: 3;
      }
    }
    .social_block {
      a {
        & + a {
          margin-left: 15px;
        }
      }
    }
    .phone_area {
      a {
        color: #fff;
        text-decoration: none;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        &:hover,
        &:focus {
        color: #a3df4a;
        }
      }
      .phone-item {
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
    }
  }

  .hero {
    padding-top: 150px;

    @media screen and (max-width: 991px) {
      padding-top: 0px;
    }
  }

  .navigation_filter {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    a {
      text-decoration: none;
      color: #fff;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      line-height: normal;
      transition: 0.5s;
      position: relative;
      cursor: pointer;

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
      &:first-child {
        &::after {
          content: "";
          width: 1px;
          height: 48px;
          background: #fff;
          position: absolute;
          right: -20px;
          top: -10px;
        }
      }

      &.active {
        font-weight: 700;
        text-decoration-line: underline;
      }
      & + a {
        margin-left: 40px;
      }
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
.nav {
  display: flex;
  align-items: center;
  li {
    list-style-type: none;
    & + li {
      margin-left: 10px;
    }
    a {
      text-decoration: none;
      color: #fff;
      font-weight: bold;
      transition: 0.5s;
      &:hover,
      &:focus {
        color: #a3df4a;
      }
      &.active {
        color: #a3df4a;
      }
    }
  }
}
.fillter_wrap {
  box-sizing: border-box;
  overflow: hidden;
  margin: 10px;
  @media screen and (max-width: 991px) {
    margin: 10px auto;
    max-width: 90vw;
  }
}
.fillter {
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 830px;
  width: 100%;
  position: relative;
  z-index: 2;
  @media screen and (max-width: 991px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    .brand {
      order: 3;
      grid-column: 1 / -1;
    }
  }
  &-result {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 auto;
    max-width: 830px;
    width: 100%;
    opacity: 0;
    background: #d9d9d9;
    padding: 15px;
    transform: translateY(-100%);
    max-height: 200px;
    overflow-y: scroll;
    transition: transform 0.5s, opacity 0.5s;
    @media screen and (max-width: 991px) {
      display: flex;
      max-width: 100vw;
    }
    &.active {
      opacity: 1;
      transform: translateY(0);
    }
    li {
      list-style: none;
      background: #a3df4a;
      max-width: 152px;
      width: 100%;
      padding: 12.5px 0;
      text-align: center;
      cursor: pointer;
      transition: 0.5s;
      margin-bottom: 10px;
      @media screen and (max-width: 991px) {
        margin-right: 20px;
        padding: 12px 10px;
        white-space: nowrap;
        display: table;
      }
      &.active {
        background: #7aac2f;
        color: #fff;
        position: relative;
        &::after {
          content: "";
          background: url(../image/checked.png) no-repeat;
          width: 18px;
          height: 18px;
          position: absolute;
          right: 5px;
          top: 5px;
        }
      }
      &:hover {
        background: #7aac2f;
      }
    }
  }
  .item {
    background: #fff;
    height: 54px;
    display: none;
    align-items: center;
    padding: 0 13px;
    position: relative;
    cursor: pointer;
    width: 100%;
    @media screen and (max-width: 991px) {
      order: 1;
      justify-content: space-between;
    }
    &::before {
      content: "";
      width: 1px;
      height: 44px;
      background: #000;
      position: absolute;
      right: 0;
      opacity: 0.2;
    }
    &:nth-child(5) {
      &::before {
        content: "";
        display: none;
      }
    }
    &.active {
      display: flex;
    }
    &-title {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      & img {
        margin-left: 10px;
      }
    }
    & > img {
      margin-left: 10px;
    }
  }
  .search {
    background: #a3df4a;
    padding: 0 30px;
    height: 54px;
    border: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
    width: auto;
    @media screen and (max-width: 991px) {
      order: 5;
      grid-column: 1/-1;
      width: 100%;
      justify-content: center;
      font-weight: 700;
      letter-spacing: 0.7px;
      text-transform: uppercase;
    }
    & img {
      margin-right: 10px;
    }
    &:hover {
      background: #7aac2f;
    }
  }
}
.main {
  h2 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 50px 0;
  }
}

.fillter-result_area {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  .fra_item {
    margin-left: 15px;
    display: flex;
    align-items: center;
    text-decoration: underline;
    a {
      margin-left: 7px;
      display: table;
      position: relative;
      bottom: -1px;
    }
  }
}
.single_main {
  position: relative;
  .fill_1 {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 40%;
  }
  .fill_2 {
    position: absolute;
    left: 0;
    top: -20%;
  }
  .fill_3 {
    position: absolute;
    right: 0;
    transform: rotate(-180deg);
    top: 20%;
  }
}
.catalog {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;


  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: 576px) and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
  .item {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    margin: 20px;
    padding: 20px;
    position: relative;
    &::after {
      content: "";
      width: 8px;
      height: 47px;
      display: table;
      background: #a3df4a;
      position: absolute;
      left: 0;
      top: 0;
    }
    &::before {
      content: "";
      width: 47px;
      height: 8px;
      display: table;
      background: #a3df4a;
      position: absolute;
      left: 0;
      top: 0;
    }
    .image {
      margin: 0 auto;
      display: table;
      max-width: 263px;
      img {
        width: 100%;
      }
    }
    .special {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      img {
        margin-bottom: 5px;
      }
    }
    .item_info {
      &::after {
        content: "";
        width: 8px;
        height: 47px;
        display: table;
        background: #a3df4a;
        position: absolute;
        right: 0;
        bottom: 0;
      }

      &::before {
        content: "";
        width: 47px;
        height: 8px;
        display: table;
        background: #a3df4a;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .title {
        text-align: center;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: table;
        width: 80%;

        display: table;
        margin: 0 auto;
        margin-bottom: 15px;
      }
      .price {
        color: #3b5c08;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 15px;
      }
      .item_nav {
        display: table;
        margin: 0 auto;
        .more {
          background: #dddcdc;
          text-decoration: none;
          color: #000;
          font-size: 12px;
        }
        .order {
          background: #a3df4a;
        }
      }
      .btn {
        padding: 12px 10px;
        border: 0;
        transition: 0.5s;
        font-weight: 700;
        cursor: pointer;
        &:hover {
          opacity: 0.5;
        }
        & + .btn {
          margin-left: 15px;
        }
      }
    }
  }
}

.load {
  max-width: 350px;
  width: 100%;
  display: table;
  margin: 30px auto;
  background: #a3df4a;
  padding: 20px 0;
  border: 0;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
footer {
  background: #000;
  color: #fff;
  padding: 50px 0;
  font-size: 12px;
  margin-top: 110px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
  @media screen and (max-width: 1200px) {
    // Стили для экранов шириной до 1200px
  }

  @media screen and (max-width: 992px) {
    // Стили для экранов шириной до 992px
  }

  @media screen and (max-width: 768px) {
    // Стили для экранов шириной до 768px
  }

  @media screen and (max-width: 991px) {
    // Стили для экранов шириной до 991px
  }
  &::after {
    content: "";
    background: url(../image/speed.png) no-repeat;
    width: 128px;
    height: 129px;
    position: absolute;
    top: -115px;
    left: 0;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    @media screen and (max-width: 991px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      align-items: center;
      justify-content: center;
      justify-items: center;
    }
  }
  .phone_area {
    a {
      color: #fff;
      text-decoration: none;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .phone-item {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }
  }
  .clock,
  .point {
    display: flex;
    align-items: center;
    img {
      margin-right: 12px;
    }
  }
  small {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    display: table;
    width: 100%;
  }
}

// single product

.single_product_header {
  background: #a3df4a;
  padding: 25px 0;
  position: relative;
  z-index: 3;
  .container {
    display: flex;
    align-content: inherit;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 991px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      grid-gap: 29px;
      .nav {
        order: 3;
        grid-column: 1 / -1;
      }
    }
  }
  .phone_area {
    a {
      color: #000;
      text-decoration: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .phone-item {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }
  }
}
.product_ditails {
  h1 {
    margin: 40px 0;
  }
}
.product-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin-bottom: 39px;
  & > img {
    width: 80%;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    justify-items: center;
    grid-gap: 20px;
  }
  .product-list-info {
    .item {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .top-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    span.title {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      img {
        margin-right: 10px;
      }
    }
  }
}

.order_product_list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  .callback {
    width: 100%;
    border: 4px solid #000;
    position: relative;
    padding: 20px;
    .call_button {
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 10px;
      translate: 0.5s;
      &:hover {
        opacity: 0.5;
      }
    }
    .title {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 5px;
      display: table;
    }
    p {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 5px;
      & + a {
        color: #3b5c08;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: lowercase;
      }
    }
  }
}

.order_nav {
  background: #a3df4a;
  padding: 20px;
  .top {
    display: flex;
    justify-content: space-between;
    img {
      & + img {
        margin-left: 20px;
      }
    }
    .quantity {
      display: flex;
      align-items: center;
      & > span {
        margin-right: 30px;
        font-weight: 700;
      }
      .quantity-controls {
        display: flex;
        align-items: center;
        span {
          display: table;
          width: 30px;
          background: #fff;
          text-align: center;
          margin: 0 10px;
        }
        button {
          border: 0;
          background: transparent;
          font-size: 16px;
          width: 15px;
          text-align: center;
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .price {
      color: #000;
      font-size: 27px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .add_to_cart {
      background: #000;
      color: #fff;
      padding: 10px 40px;
      cursor: pointer;
      img {
        margin-right: 10px;
      }
    }
  }
}

.related_product {
  h2 {
    color: #000;
    font-family: Inter;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin: 50px 0;
  }
  .related_post_area {
    background: #d9d9d9;
    padding: 5px;
    .item {
      display: grid;
      grid-template-columns: 10% 60% 15% 15%;
      background: #fff;
      margin-bottom: 5px;
      align-items: center;
      padding: 10px 0;
      @media screen and (max-width: 991px) {
        grid-template-columns: 20% 80%;
        // justify-items: center;
        padding-left: 10px;
        padding-right: 10px;
      }
      & > img {
        width: 87px;
        padding-left: 30px;
        @media screen and (max-width: 991px) {
          padding-left: 0;
        }
      }
      .hero {
        padding-left: 30px;
        @media screen and (max-width: 991px) {
          padding-left: 20px;
        }
        & > .title {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          display: table;
          margin-bottom: 20px;
        }
        .ditail {
          display: flex;
          align-items: center;
          & > .item {
            display: flex;
            align-items: center;
            flex-direction: column;
            & + .item {
              margin-left: 10px;
            }
            .title {
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
            .description {
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              margin-left: 5px;
              color: #3b5c08;
            }
          }
        }
      }
      .price {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media screen and (max-width: 991px) {
          white-space: nowrap;
        }
      }
      .ditail_navigation {
        display: flex;
        align-items: center;
        @media screen and (max-width: 991px) {
          width: 100%;
          // justify-content: flex-end;
          padding-left: 20px;
        }
        a {
          width: 62px;
          height: 62px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 0.5s;
          &:hover {
            opacity: 0.5;
          }
          &.addt_to_cart_ditail {
            background: #a3df4a;
          }
          &.more {
            background: #dddcdc;
          }
          & + a {
            margin-left: 10px;
          }
        }
        img {
          width: 24px;
        }
      }
    }
  }
}

.modal_order {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  &.active {
    display: block;
  }
  .spinner, .success-message {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #8BC34A;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .success-message {
    img {
      width: 150px;
      margin-bottom: 20px;
    }
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #a3df4a;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-bottom: 20px;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .modal_order_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      width: 90%;
    }
    button.close {
      position: absolute;
      right: 10px;
      top: 10px;
      background: transparent;
      border: 0;
      font-size: 20px;
      font-family: cursive;
      line-height: 20px;
      cursor: pointer;
      z-index: 4;
    }
    .left {
      background: #a3df4a;
      .product_modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .top_level {
          background: #fff;
          display: grid;
          grid-template-columns: 20% 60% 20%;
          margin: 10px;
          align-items: center;
          padding: 10px;
          img {
            width: 100%;
          }
          span.title {
            font-weight: 700;
            font-size: 14px;
            display: table;
            margin-bottom: 5px;
          }
          .quantity-controls {
            display: flex;
            align-items: center;
            button {
              border: 0;
              background: #a3df4a;
              padding: 11px 10px;
              line-height: 0;
            }
            & > span {
              margin: 0 10px;
              background: #e0e0e0;
              padding: 2px 10px;
            }
          }
        }
        .bottom_level {
          background: #fff;

          margin: 0 10px 10px;
          align-items: center;
          padding: 10px;
          @media screen and (max-width: 991px) {
            display: none;
          }
          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            & span:first-child {
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 700;
            }
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
    .right {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .shipping {
        position: relative;
        border: 2px solid #a3df4a;
        padding: 20px;
        border-radius: 5px;
        margin-top: 20px;
        img {
          position: absolute;
          top: -17px;
          background: #fff;
        }
        span.title {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 12px;
          display: table;
          margin-bottom: 10px;
        }
        p {
          margin: 0;
          font-size: 12px;
        }
        a {
          font-size: 12px;
          text-decoration: none;
        }
      }
      h2 {
        margin-bottom: 30px;
      }
      p {
        font-size: 14px;
        margin-bottom: 30px;
      }
      input {
        width: 100%;
        padding: 5px 10px;
        border: 0;
        margin-bottom: 10px;
        border-bottom: 2px solid #a3df4a;
        outline: none;
      }
      button {
        background: #a3df4a;
        width: 100%;
        border: 0;
        padding: 15px 0;
        text-transform: uppercase;
      }
      small {
        font-style: 12px;
        margin-bottom: 10px;
        display: table;
      }
    }
  }
}

.product-list-info {
  padding-right: 50px;
}
